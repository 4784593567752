<template>
    <component :is="SkinComponent" v-model="form" :code="code" @save="save"></component>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data() {
        return {
            form: {
                code: this.$props.code,

                subject: undefined,
                content: null,

                writer: {
                    _id: null,
                    name: null,
                    phone: null,
                    phone1: "010",
                    phone2: null,
                    phone3: null,
                    email: null,
                    email1: null,
                    email2: null,
                    email3: ""
                },

                meta: {}
            },
        };
    },

    methods: {
        validate() {
            try {
                if (this.secret) {
                    if (this.board.password == null || this.board.password.length !== 6) throw new Error("비밀번호는 6자리 숫자여야 합니다.");
                }

                return true;
            } catch (error) {
                this.handleError(error);
            }
            return false;
        },

        async save() {
            if(this.validate()){
                await api.v1.forms.post(this.form);

                alert("저장되었습니다");

                this.$router.refresh();
            }
        },

        handleError(error) {
            console.error(error);
            alert(error.repsonse ? error.response.data.message : error.message);
        },
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        }
    },
};
</script>
